<template>
  <div>
    <v-card>
      <v-card-title>Atualizar termos e política de privacidade</v-card-title>

      <v-card-text>
        <v-row class="mt-1">
          <v-col>
            <v-textarea
              label="Termos e política de privacidade"
              v-model="form.terms"
              :loading="loading"
              class="required"
              hint="Informe o texto desejado para os termos e política de privacidade."
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col class="ml-2">
            <v-btn
              small
              class="primary darken-1 mr-2"
              @click="save()"
              :loading="loading"
            >
              <v-icon left>mdi-content-save</v-icon> {{ saveButtonText }}
            </v-btn>
            <v-btn
              outlined
              small
              text
              @click="closeOrCancel()"
              :loading="loading"
            >
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeOrCancel()">Ok</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    entityId() {
      return this.mode === "update" ? this.$route.params.entityId : null;
    },
    saveButtonText() {
      return this.mode === "insert" ? "Cadastrar" : "Salvar alterações";
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      successDialog: false,
    };
  },
  methods: {
    validateForm() {
      const errors = [];

      if (!this.form.terms)
        errors.push("O campo TERMOS E POLÍTICA DE PRIVACIDADE é obrigatório");

      return errors;
    },
    save() {
      const errors = this.validateForm();

      if (errors.length) return this.$root.$errorDialog(errors);

      this[this.mode]();
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(`/terms`, this.form);

        this.successDialog = true;
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async initializeForm() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`/terms`);

        this.form = { ...res.data };
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
    closeOrCancel() {
      if (this.modal) this.$emit("close-dialog");
      else this.$router.go(-1);
    },
  },
  created() {
    if (this.mode === "update") this.initializeForm();
  },
};
</script>

<style scoped>
.required label::after {
  content: " *";
  color: red;
}
</style>
